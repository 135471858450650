/*
 * © by christian at kumpe.de
 *
 * If you want to copy or use the code in any way, ask for permission.
 */
import $ from "jquery";

const kumpe = kumpe || {};

/*
 * Mail address obfuscation.
 */
kumpe.MailAddressDeobfuscator = function () {
  const characterMap = {
    at: "@",
    dot: ".",
    ddot: ":"
  };

  this.deobfuscate = function (element) {
    element.href = "mailto:" + (element.innerHTML = element.innerHTML.replace(/\s*\[(.*?)]\s*/g, function () {
      return characterMap[arguments[1]];
    }));
  };
};

$.fn.deobfuscate = function () {
  const mailAddressDeobfuscator = new kumpe.MailAddressDeobfuscator();
  return this.each(function () {
    mailAddressDeobfuscator.deobfuscate(this);
  });
};

$(function () {
  $("a.obfuscatedMail").deobfuscate();

  $('.slide').slick({
    arrows: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000
  }).click(function () {
    $(this).slick("slickNext");
  });
});
